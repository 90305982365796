@import "libs/theme/scss/global_variables";

.full-with {
  .ng-dropdown-panel {
    left: 0;
    width: fit-content;
  }
}
.ng-dropdown-panel {
  right: 0;
  top: 45px;

  .ng-dropdown-panel-items {
    border-radius: 0 0 16px 16px;
    background: $brand-white;
    box-shadow: 0 32px 32px 0 rgba($brand-black, 0.1);
    width: fit-content;
    min-width: 215px;

    .ng-option {
      font-weight: bold;
      padding: 10px 15px;
      color: $brand_black;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background: $secondary_10;
      }

      &.ng-option-selected {
        color: $brand-secondary;
      }
    }
  }

  .ng-dropdown-header {
    .btn-select-header {
      border: none;
      background: $brand_white;
      box-shadow: 0 32px 32px 0 rgb(0 0 0 / 10%);
      color: $brand-black;
      height: 40px;

      &:hover {
        background: $brand_primary;
        color: $brand_white;
      }

      .btn-text {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 48px;
    bottom: -8px;
    border-radius: 16px;
    z-index: -1;
  }
}
.brand-primary {
  color: $brand-primary;
}
